import classNames from 'classnames';

export function VKCTA({ className }) {
  return (
    <div className={
      classNames(
        className,
        'mx-6 md:m-8 md:shadow-xl md:shadow-gray-100 col-span-4 lg:col-span-2 rounded-2xl justify-between flex'
      )
    }>
      <div className="md:p-8">
        <div className="flex flex-col justify-between z-10">
          <p className="flex items-center gap-2 text-xl font-medium mb-3">
            мы в VK
          </p>
          <p>напоминаем о праздниках, показываем свои работы и отвечаем на ваши сообщения</p>

          <a
            target="_blank"
            aria-label="наша группа вконтакте"
            href="https://vk.com/flopus.flowers"
            className="mt-4 block link" rel="noreferrer"
          >
            <span className="bg-clip-text text-xl font-semibold text-transparent bg-blue-600 flex gap-1.5 items-center">
              <img height="20" width="20" alt="" src={'/icons/vk.svg'}/> flopus.flowers
            </span>
          </a>
        </div>
      </div>

      <img alt="" className="hidden xl:block h-48 w-48 mr-4 self-end" src={'/instagram-mockup.png'}/>
    </div>
  )
}
