import { CheckCircleIcon } from '@heroicons/react/24/outline';
import classNames from 'classnames';
import { Button, FormError, InputPhone } from 'components/common';
import { ErrorMessage, Form, Formik } from 'formik';
import { useState } from 'react';
import { sendForm } from 'services/api/utils.service';
import { getFormattedPhoneNumber } from 'utils';
import * as yup from 'yup';

const schema = yup.object().shape({
  phone: yup.string().required('Укажите номер телефона').matches(/^\+\d \d{3} \d{3} \d{4}$/, 'Введите полный номер телефона').default('')
});

export function CallbackCTA({ className }) {
  const [ phone, setPhone ] = useState('');

  return (
    <div className={
      classNames(
        className,
        'md:mx-6 md:m-8 md:shadow-xl md:shadow-gray-100 col-span-4 lg:col-span-2 rounded-2xl justify-between flex'
      )
    }>
      <div className="md:p-8">
        <div>
          <p className="flex items-center gap-2 text-xl font-medium mb-3">
            <span>помочь с выбором?</span>
          </p>
          <p>ответим на любые вопросы, поможем с выбором, обсудим уникальный букет или композицию</p>
        </div>
        {
          !phone &&
          <Formik
            initialValues={{ phone: '' }}
            validationSchema={schema}
            onSubmit={values => {
              sendForm('callback', { phoneNumber: values.phone })
                .then(() => {
                  setPhone(values.phone)
                });
            }}
          >
            <Form>
              <div className="flex gap-2 mt-4">
                <div>
                  <InputPhone label="телефон" name="phone" placeholder="Телефон"/>
                </div>
                <Button
                  aria-label="Отправить"
                  color="blue"
                  type="submit"
                  sx="text-md opacity-90 hover:opacity-100"
                >
                  Отправить
                </Button>
              </div>

              <ErrorMessage name="phone" component={FormError}/>
            </Form>
          </Formik>
        }
        {
          phone && <div className="flex gap-4 mt-4 items-center">
            <CheckCircleIcon className="w-6 h-6 text-green-500"/>
            <span>
            Отлично! Свяжемся с вами по номеру&nbsp;
              <span className="leading-8 whitespace-nowrap flex gap-2 items-center">
                <b>{ getFormattedPhoneNumber(phone) }</b>
                <span className="text-sm font-semibold text-blue-600 cursor-pointer" onClick={() => setPhone('')}>изменить</span>
              </span>
            </span>
          </div>
        }
      </div>
      <img className="hidden xl:block h-40 w-50 mr-4 self-end" src={'/callback-cta.png'} alt=""/>
    </div>
  )
}
