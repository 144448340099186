import { ArrowRightIcon } from '@heroicons/react/24/solid';
import { useIntersectionObserver, useWindowSize } from '@react-hookz/web';
import cn from 'classnames';
import styles from 'components/categories/CategoryCarousel/CategoryCarousel.module.css';
import { ProductCard } from 'components/products';
import Link from 'next/link';
import { useRef, useState } from 'react';

import styles2 from './CategoryCarousel.module.css';


export default function CategoryCarousel2({ index, products, category }) {
  const { width } = useWindowSize();
  const navigationBreakpoints = useRef([]);
  const rootRef = useRef(null);
  const showMoreCardRef = useRef(null);
  const [ _currentSlide, _setCurrentSlide ] = useState(0);
  const COLS_COUNT = width >= 768 ? 4 : 2;
  const isLastSlide = (products.length / COLS_COUNT) - 1 < _currentSlide;

  const intersection = useIntersectionObserver(showMoreCardRef, {
    root: rootRef,
    threshold: [ 0.3, 1 ]
  });

  const setCurrentSlide = (i) => {
    if (!navigationBreakpoints.current[i]) {
      return;
    }

    navigationBreakpoints.current[i].scrollIntoView({
      block: 'nearest',
      behavior: 'smooth',
      inline: 'start'
    });

    _setCurrentSlide(i);
  }

  return (
    <div className="gap-4 -mt-1 -mb-1 md:mt-20 bg-white">
      <div className="hidden md:flex absolute right-4">
        <div
          className={cn(_currentSlide === 0 ? 'opacity-40' : 'hover:text-blue-600', styles.move)}
          onClick={() => setCurrentSlide(_currentSlide - 1)}
        >
          <div className="w-8 h-6 bg-no-repeat" style={{ backgroundImage: 'url(/icons/left-arrow.svg)' }}/>
        </div>
        <div
          className={cn(isLastSlide ? 'opacity-50' :  'hover:text-blue-600', styles.move)}
          onClick={() => setCurrentSlide(_currentSlide + 1)}
        >
          <div className="w-8 h-6 bg-no-repeat" style={{ backgroundImage: 'url(/icons/right-arrow.svg)' }}/>
        </div>
      </div>
      <Link
        prefetch={false}
        href={`/${category.slug}`}
        aria-label={category.title}
        className={styles.title}
      >
        <h2 className={styles.titleLink}>{ category.title.toLowerCase() }</h2>
        <span className={cn(intersection?.isIntersecting && 'opacity-0', 'text-blue-600 transition-all md:hidden whitespace-nowrap flex items-center px-3.5 py-1.5 rounded-full font-medium text-sm mr-2')}>
          показать все
        </span>
      </Link>
      <div ref={rootRef} className={cn(styles2.container, 'no-scrollbar')}>
        {
          products.map((product, pIndex) =>
            <ProductCard
              {
                ... (pIndex % COLS_COUNT === 0) && {
                  ref: (el) => navigationBreakpoints.current[pIndex / COLS_COUNT] = el
                }
              }
              // ref={el => navigationBreakpoints.current[pIndex / COLS_COUNT] = el}
              key={`${index}_${pIndex}`}
              lazy={index > 1 || pIndex > 4}
              className="snap-start"
              // style={{ flexShrink: '0' }}
              product={product}
              size={600}
              slug={product.fullSlug}
            />
          )
        }
        <Link
          prefetch={false}
          aria-label={category.title}
          href={`/${category.slug}`}
          style={{ paddingBottom: '100%' }}
          className="relative overflow-hidden rounded-lg"
          ref={showMoreCardRef}
        >
          <div className={cn('flex group flex-col gap-4 items-center justify-center', styles.categorySlide)}>
            <div className="w-14 h-14 flex bg-white md:bg-gray-200/70 md:shadow-none shadow-lg shadow-black/5 rounded-full justify-center items-center">
              <ArrowRightIcon className="w-7 h-7"/>
            </div>
            <p className="group-hover:text-blue-600 font-medium text-sm">Показать все</p>
          </div>
        </Link>
      </div>
    </div>

  )
}
